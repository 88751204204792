import React, { useEffect, useState } from "react";
import { dynamic_content_url } from '../config.json';
import Layout from "../components/layout";
import "../scss/homepage.scss";

/**
 * The root page.
 */
export default function Home() {
  const [content, setContent] = useState({ reasons: [], bg_image: '', industries: [] });

  useEffect(() => {
    fetch(dynamic_content_url)
      .then(response => response.json())
      .then(data => {
        let len = Math.ceil(data.home.industries.length / 3)
        let split_ind = [
          data.home.industries.splice(0,len),
          data.home.industries.splice(0,len),
          data.home.industries.splice(0,len),
        ]
        setContent({...data.home, industries: split_ind})
      });
  }, []);

  return (
    <Layout>
      <div className="hero-custom">
        <div className="hero-image" style={{ backgroundImage: `url('${content.bg_image}')` }}/>
  	    <div className="inner-vertical-center">
          <div className="container">
						<div className="hero-text">
              <span className="title">24/7 Home Tours</span>
              <br />
  						<span className="subtitle">From your home to theirs</span>
            </div>
	  		  </div>
        </div>
	  	</div>
      <div className="why-get-tour">
        <div className="container">
          <div className="why-padding">
            <span className="homepage-title">why get a 3d tour?</span>
            <div className="columns">
              {content.reasons.map(({title, body}) => (
                  <div key={title} className="column">
                    <div className="neumorphic-box">
                      <div className="reason-subtitle">{title}</div>
                      <div className="reason-body">{body}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-bottom">
        <div className="container ">
          <h1 
            className="homepage-title" 
            style={{ color: 'white' }}>Industries</h1>
          <h2 className="white subtitle">Who we work with</h2>
          <div className="columns">
            {content.industries.map((arr, k) => (
              <div key={`${k}-col`} className="column industry-list content" style={{margin: 0}}>
                <ul>
                  {arr.map((industry, i) => <li key={`${i}-ind`}>{industry}</li>)}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}